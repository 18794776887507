import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-634c1de4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-symptoms" }
const _hoisted_2 = { class: "order-symptoms__patients" }
const _hoisted_3 = { class: "white-box" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_tags = _resolveComponent("tags")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasPermission(_ctx.actions.SHOW_ORDER_STEPS))
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "order-symptoms__steper",
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              xs: { span: 24 },
              lg: { span: 16 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_steps, { current: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_step, {
                      disabled: "",
                      title: _ctx.t('ORDER_PERSONAL.STEP_TITLE')
                    }, null, 8 /* PROPS */, ["title"]),
                    _createVNode(_component_a_step, {
                      title: _ctx.t('ORDER_SYMPTOMS.STEP_TITLE')
                    }, null, 8 /* PROPS */, ["title"]),
                    (_ctx.hasPermission(_ctx.actions.SHOW_PRICE) && _ctx.showPaymentStep)
                      ? (_openBlock(), _createBlock(_component_a_step, {
                          key: 0,
                          disabled: "",
                          title: _ctx.t('ORDER_PAYMENT.STEP_TITLE')
                        }, null, 8 /* PROPS */, ["title"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_a_row, {
      style: {"margin-top":"32px"},
      type: "flex"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          lg: { span: 19 },
          md: { span: 19 },
          sm: { span: 24 },
          xs: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.t("ORDER_SYMPTOMS.HEADER")), 1 /* TEXT */),
            _createElementVNode("p", null, _toDisplayString(_ctx.t(`ORDER_SYMPTOMS.${_ctx.translationType}.SUB_HEADER`)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patients, (patient, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "order-symptoms__patient",
          key: index,
          style: {"margin-top":"24px"}
        }, [
          _createVNode(_component_a_form, {
            ref: 
            (el) => {
              if (el) _ctx.patientsFormTemplate[index] = el;
            }
          ,
            model: patient.model,
            autocomplete: "off",
            rules: patient.rules
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.patients.length > 1)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.t("ORDER_SYMPTOMS.PATIENT.HEADER", { index: index + 1 })), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                _withDirectives(_createVNode(_component_a_row, { class: "white-box__row order-symptoms__predefined" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      lg: 5,
                      md: 5,
                      sm: 5,
                      xs: 10,
                      class: "white-box__field-name"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          innerHTML: _ctx.t('ORDER_SYMPTOMS.PATIENT.PREDEFINED_SYMPTOMS')
                        }, null, 8 /* PROPS */, _hoisted_5)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_a_col, {
                      lg: { span: 16, offset: 1 },
                      md: { span: 16, offset: 1 },
                      sm: { span: 16, offset: 2 },
                      xs: { span: 12, offset: 2 },
                      class: "white-box__value"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "predefinedSymptoms" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_tags, {
                              value: patient.model.predefinedSymptoms,
                              "onUpdate:value": ($event: any) => ((patient.model.predefinedSymptoms) = $event),
                              "empty-text": _ctx.t('NO_DATA'),
                              loading: 5,
                              data: _ctx.predefinedSymptomsOptions,
                              onChange: ($event: any) => (_ctx.onPredefinedSymptomsChange(index))
                            }, null, 8 /* PROPS */, ["value", "onUpdate:value", "empty-text", "data", "onChange"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */), [
                  [_vShow, _ctx.hasPermission(_ctx.actions.SHOW_SYMPTOM_CHECKER)]
                ]),
                _createVNode(_component_a_row, { class: "white-box__row order-symptoms__other" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      lg: 5,
                      md: 5,
                      sm: 5,
                      xs: 10,
                      class: "white-box__field-name"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.hasPermission(_ctx.actions.SHOW_SYMPTOM_CHECKER)
                    ? _ctx.t("ORDER_SYMPTOMS.PATIENT.OTHER_SYMPTOMS")
                    : _ctx.t("ORDER_SHORT.ORDER_SYMPTOMS.SYMPTOMS")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_a_col, {
                      lg: { span: 16, offset: 1 },
                      md: { span: 16, offset: 1 },
                      sm: { span: 16, offset: 2 },
                      xs: { span: 12, offset: 2 },
                      class: "white-box__value"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "otherSymptoms" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              onChange: ($event: any) => (_ctx.onOtherSymptomsChange(index)),
                              value: patient.model.otherSymptoms,
                              "onUpdate:value": ($event: any) => ((patient.model.otherSymptoms) = $event),
                              placeholder: "np. Ból pleców",
                              "auto-size": { minRows: 4, maxRows: 4 }
                            }, null, 8 /* PROPS */, ["onChange", "value", "onUpdate:value"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model", "rules"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(_component_a_row, {
      style: {"margin":"64px 0 42px 0"},
      class: "order-symptoms__footer",
      type: "flex"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          lg: { span: 16 },
          md: { span: 20 },
          sm: { span: 22 },
          xs: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              class: "order-symptoms__submit",
              onClick: _ctx.submit,
              disabled: !_ctx.valid || _ctx.submitLoading,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("ORDER_SYMPTOMS.SUBMIT")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick", "disabled"]),
            _createVNode(_component_router_link, { to: "/make-appointment" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, { class: "order-symptoms__cancel" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("ORDER_SYMPTOMS.CANCEL")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}